.App {
  text-align: center;
  
}

.navStyle{
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(84, 138, 109);
  color: white;
}

.navStyle-Links{
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  
}


.linkStyle{
  color:white;
}
